
import Vue from 'vue';
import {Component} from "vue-property-decorator";
import {CONTAINER_STORE_NAME, ContainerStoreActions} from "@/store/container.store";
import {namespace} from "vuex-class";
import ContainerEvent from "@/models/ContainerEvent.model";
import {APPLICATION_STORE_NAME} from "@/store/application.store";

const ContainerStore = namespace(CONTAINER_STORE_NAME);

@Component({
  components: {
    HistoryListComponent: () => import(
      /* webpackChunkName: "StoreListComponent" */
      '@/components/History/HistoryView.component.vue'
    ),
  }
})
export default class ContainersView extends Vue {
  @ContainerStore.Action(ContainerStoreActions.GET_HISTORY)
  private loadHistory!: (companyId: string) => Promise<ContainerEvent[]>;

  private isLoading = false;

  private history: ContainerEvent[] = [];

  private search: string = '';

  async created() {
    try {
      this.isLoading = true;
      this.history = await this.loadHistory(this.$route.params.id);

      // reverses list to show the newest entries at first
      this.history.reverse();
    } finally {
      this.isLoading = false;
    }
  }
}
